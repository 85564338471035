<template>
  <div class="px-2 py-5">
    <h1 class="title">CUESTIONARIO CAT</h1>
    <b-message type="is-info">
      Este cuestionario le ayudará¡ a usted y a su médico medir el impacto sobre
      su bienestar y calidad de vida que tiene la EPOC (Enfermedad Pulmonar
      Obstructiva Crónica). La puntuación de la prueba, puede ser utilizado por
      usted y su profesional de la salud para ayudar a mejorar el tratamiento de
      su EPOC y obtener mejores resultados. Para cada pregunta, coloque una
      marca en el cuadro que mejor lo describe.
      <br />
      <br />
      Califique de 0 a 5 el valor que mas se aproxime a su evaluación
    </b-message>

    <div class="block">
      <span>
        1.
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Yo nunca toso</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.CAT_P1"
            :native-value="answers.CAT_P1.CAT_P1_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            0
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.CAT_P1"
            :native-value="answers.CAT_P1.CAT_P1_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            1
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.CAT_P1"
            :native-value="answers.CAT_P1.CAT_P1_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            2
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.CAT_P1"
            :native-value="answers.CAT_P1.CAT_P1_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            3
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.CAT_P1"
            :native-value="answers.CAT_P1.CAT_P1_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            4
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.CAT_P1"
            :native-value="answers.CAT_P1.CAT_P1_R6"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            5
          </b-radio>
        </div>
        <div class="column has-text-left">
          <strong>Toso todo el tiempo</strong>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        2.
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>No tengo flema (moco en el pecho)</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.CAT_P2"
            :native-value="answers.CAT_P2.CAT_P2_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            0
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.CAT_P2"
            :native-value="answers.CAT_P2.CAT_P2_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            1
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.CAT_P2"
            :native-value="answers.CAT_P2.CAT_P2_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            2
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.CAT_P2"
            :native-value="answers.CAT_P2.CAT_P2_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            3
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.CAT_P2"
            :native-value="answers.CAT_P2.CAT_P2_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            4
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.CAT_P2"
            :native-value="answers.CAT_P2.CAT_P2_R6"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            5
          </b-radio>
        </div>
        <div class="column has-text-left">
          <strong>Tengo el pecho lleno de flema (moco)</strong>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        3.
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>No siento el pecho oprimido</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.CAT_P3"
            :native-value="answers.CAT_P3.CAT_P3_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            0
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.CAT_P3"
            :native-value="answers.CAT_P3.CAT_P3_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            1
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.CAT_P3"
            :native-value="answers.CAT_P3.CAT_P3_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            2
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.CAT_P3"
            :native-value="answers.CAT_P3.CAT_P3_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            3
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.CAT_P3"
            :native-value="answers.CAT_P3.CAT_P3_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            4
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.CAT_P3"
            :native-value="answers.CAT_P3.CAT_P3_R6"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            5
          </b-radio>
        </div>
        <div class="column has-text-left">
          <strong>Siento el pecho oprimido.</strong>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        4.
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong
            >No me falta el aliento al subir pendientes o escaleras
          </strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.CAT_P4"
            :native-value="answers.CAT_P4.CAT_P4_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            0
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.CAT_P4"
            :native-value="answers.CAT_P4.CAT_P4_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            1
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.CAT_P4"
            :native-value="answers.CAT_P4.CAT_P4_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            2
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.CAT_P4"
            :native-value="answers.CAT_P4.CAT_P4_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            3
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.CAT_P4"
            :native-value="answers.CAT_P4.CAT_P4_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            4
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.CAT_P4"
            :native-value="answers.CAT_P4.CAT_P4_R6"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            5
          </b-radio>
        </div>
        <div class="column has-text-left">
          <strong>Me falta el aliento al subir pendientes o escaleras.</strong>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        5.
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>No tengo limitación para tareas del hogar </strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.CAT_P5"
            :native-value="answers.CAT_P5.CAT_P5_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            0
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.CAT_P5"
            :native-value="answers.CAT_P5.CAT_P5_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            1
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.CAT_P5"
            :native-value="answers.CAT_P5.CAT_P5_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            2
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.CAT_P5"
            :native-value="answers.CAT_P5.CAT_P5_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            3
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.CAT_P5"
            :native-value="answers.CAT_P5.CAT_P5_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            4
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.CAT_P5"
            :native-value="answers.CAT_P5.CAT_P5_R6"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            5
          </b-radio>
        </div>
        <div class="column has-text-left">
          <strong>Estoy totalmente limitado para las tareas del hogar.</strong>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        6.
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>No tengo problemas para salir de mi casa</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.CAT_P6"
            :native-value="answers.CAT_P6.CAT_P6_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            0
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.CAT_P6"
            :native-value="answers.CAT_P6.CAT_P6_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            1
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.CAT_P6"
            :native-value="answers.CAT_P6.CAT_P6_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            2
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.CAT_P6"
            :native-value="answers.CAT_P6.CAT_P6_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            3
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.CAT_P6"
            :native-value="answers.CAT_P6.CAT_P6_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            4
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.CAT_P6"
            :native-value="answers.CAT_P6.CAT_P6_R6"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            5
          </b-radio>
        </div>
        <div class="column has-text-left">
          <strong>No me siento seguro para salir de mi casa.</strong>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        7.
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Duermo profundamente</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.CAT_P7"
            :native-value="answers.CAT_P7.CAT_P7_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            0
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.CAT_P7"
            :native-value="answers.CAT_P7.CAT_P7_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            1
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.CAT_P7"
            :native-value="answers.CAT_P7.CAT_P7_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            2
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.CAT_P7"
            :native-value="answers.CAT_P7.CAT_P7_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            3
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.CAT_P7"
            :native-value="answers.CAT_P7.CAT_P7_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            4
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.CAT_P7"
            :native-value="answers.CAT_P7.CAT_P7_R6"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            5
          </b-radio>
        </div>
        <div class="column has-text-left">
          <strong>Mi problema respiratorio me impide dormir</strong>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        8.
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Tengo mucha energía</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEight"
            :name="questions.CAT_P8"
            :native-value="answers.CAT_P8.CAT_P8_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            0
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEight"
            :name="questions.CAT_P8"
            :native-value="answers.CAT_P8.CAT_P8_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            1
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEight"
            :name="questions.CAT_P8"
            :native-value="answers.CAT_P8.CAT_P8_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            2
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEight"
            :name="questions.CAT_P8"
            :native-value="answers.CAT_P8.CAT_P8_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            3
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerEight"
            :name="questions.CAT_P8"
            :native-value="answers.CAT_P8.CAT_P8_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            4
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerEight"
            :name="questions.CAT_P8"
            :native-value="answers.CAT_P8.CAT_P8_R6"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            5
          </b-radio>
        </div>
        <div class="column has-text-left">
          <strong>No tengo nada de energía</strong>
        </div>
      </div>
    </div>

    <b-message type="is-info">
      <strong>Rango de resultados</strong>
      <br />
      Impacto bajo entre 1-10
      <br />
      Impacto medio entre 11-20
      <br />
      Impacto alto entre 21-30
      <br />
      Impacto muy alto entre 31-40
    </b-message>

    <div class="block has-text-centered title">
      CAT/Resultado: {{ score }}
      <span v-if="isIncompleted">(Incompleto)</span>
      <span class="pl-3">{{ textResult }}</span>
    </div>

    <div class="buttons is-centered" v-if="!queryMode">
      <b-button class="clear-button" icon-right="block-helper" @click="clear"
        >Limpiar</b-button
      >
      <b-button class="save-button" icon-right="check-bold" @click="validate"
        >Guardar</b-button
      >
    </div>
  </div>
</template>

<script>
import { CAT as questions } from "../../../data/questions";
import { CAT as answers } from "../../../data/answers";

export default {
  data() {
    return {
      questions: questions,
      answers: answers,
      answerOne: "",
      answerTwo: "",
      answerThree: "",
      answerFour: "",
      answerFive: "",
      answerSix: "",
      answerSeven: "",
      answerEight: "",
      restoreData: {},
      queryMode: false,
    };
  },
  async created() {
    if (!this.patient && !this.$route.params.surveyId) {
      this.$router.push({ name: "CatList" }).then(() => {
        this.showToast("No has seleccionado un paciente.", "is-warning", 3000);
      });
    }

    if (this.$route.params.surveyId) {
      this.queryMode = true;
      let data = await this.getSurveyData();
      this.setEvaluationAnswers(data);
    }

    if (this.$route.params.temporalData) {
      this.answerOne = this.$route.params.temporalData.answerOne;
      this.answerTwo = this.$route.params.temporalData.answerTwo;
      this.answerThree = this.$route.params.temporalData.answerThree;
      this.answerFour = this.$route.params.temporalData.answerFour;
      this.answerFive = this.$route.params.temporalData.answerFive;
      this.answerSix = this.$route.params.temporalData.answerSix;
      this.answerSeven = this.$route.params.temporalData.answerSeven;
      this.answerEight = this.$route.params.temporalData.answerEight;
    }

    this.$global.$on("restore", () => {
      this.restoreForm();
    });
  },
  computed: {
    score() {
      return (
        parseInt(this.answerOne || 0) +
          parseInt(this.answerTwo || 0) +
          parseInt(this.answerThree || 0) +
          parseInt(this.answerFour || 0) +
          parseInt(this.answerFive || 0) +
          parseInt(this.answerSix || 0) +
          parseInt(this.answerSeven || 0) +
          parseInt(this.answerEight || 0) || 0
      );
    },
    textResult() {
      let text = "Impacto bajo";
      if (this.score >= 1 && this.score <= 10) {
        text = "Impacto bajo";
      }
      if (this.score >= 11 && this.score <= 20) {
        text = "Impacto medio";
      }
      if (this.score >= 21 && this.score <= 30) {
        text = "Impacto alto";
      }

      if (this.score >= 31 && this.score <= 40) {
        text = "Impacto muy alto";
      }

      return text;
    },
    isIncompleted() {
      return (
        this.answerOne === "" ||
        this.answerTwo === "" ||
        this.answerThree === "" ||
        this.answerFour === "" ||
        this.answerFive === "" ||
        this.answerSix === "" ||
        this.answerSeven === "" ||
        this.answerEight === ""
      );
    },
    userId: {
      get() {
        return this.$store.state.user.userData?.id;
      },
    },
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  methods: {
    cleanForms() {
      this.answerOne = "";
      this.answerTwo = "";
      this.answerThree = "";
      this.answerFour = "";
      this.answerFive = "";
      this.answerSix = "";
      this.answerSeven = "";
      this.answerEight = "";
    },
    clear() {
      this.restoreData.answerOne = this.answerOne;
      this.restoreData.answerTwo = this.answerTwo;
      this.restoreData.answerThree = this.answerThree;
      this.restoreData.answerFour = this.answerFour;
      this.restoreData.answerFive = this.answerFive;
      this.restoreData.answerSix = this.answerSix;
      this.restoreData.answerSeven = this.answerSeven;
      this.restoreData.answerEight = this.answerEight;

      this.cleanForms();
      this.clearTemporalSurveys();

      this.showUndoDialog("Se limpiaron los datos de  la encuesta CAT.");
    },
    async save() {
      try {
        this.changeLoaderStatus(true);
        let data = {
          surveyCode: "CAT",
          result: String(this.score),
          appreciation: this.textResult,
          doctorId: String(this.userId),
          patientId: String(this.patient.document),
          detail: this.setAnswers(),
        };

        await this.$surveyService.saveSurvey(data);
        this.changeLoaderStatus(false);
        this.showToast(
          "Encuesta enviada satisfactoriamente.",
          "is-success",
          3000
        );
        this.scrollTop();
        this.cleanForms();
        this.clearTemporalSurveys();
      } catch (error) {
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
    setAnswers() {
      let question1 = null;
      let question2 = null;
      let question3 = null;
      let question4 = null;
      let question5 = null;
      let question6 = null;
      let question7 = null;
      let question8 = null;

      Object.keys(this.answers[questions.CAT_P1]).forEach((key) => {
        if (this.answers[questions.CAT_P1][key] == this.answerOne) {
          question1 = {
            questionCode: questions.CAT_P1,
            answerCode: key,
            value: this.answerOne,
          };
        }
      });

      Object.keys(this.answers[questions.CAT_P2]).forEach((key) => {
        if (this.answers[questions.CAT_P2][key] == this.answerTwo) {
          question2 = {
            questionCode: questions.CAT_P2,
            answerCode: key,
            value: this.answerTwo,
          };
        }
      });

      Object.keys(this.answers[questions.CAT_P3]).forEach((key) => {
        if (this.answers[questions.CAT_P3][key] == this.answerThree) {
          question3 = {
            questionCode: questions.CAT_P3,
            answerCode: key,
            value: this.answerThree,
          };
        }
      });

      Object.keys(this.answers[questions.CAT_P4]).forEach((key) => {
        if (this.answers[questions.CAT_P4][key] == this.answerFour) {
          question4 = {
            questionCode: questions.CAT_P4,
            answerCode: key,
            value: this.answerFour,
          };
        }
      });

      Object.keys(this.answers[questions.CAT_P5]).forEach((key) => {
        if (this.answers[questions.CAT_P5][key] == this.answerFive) {
          question5 = {
            questionCode: questions.CAT_P5,
            answerCode: key,
            value: this.answerFive,
          };
        }
      });

      Object.keys(this.answers[questions.CAT_P6]).forEach((key) => {
        if (this.answers[questions.CAT_P6][key] == this.answerSix) {
          question6 = {
            questionCode: questions.CAT_P6,
            answerCode: key,
            value: this.answerSix,
          };
        }
      });

      Object.keys(this.answers[questions.CAT_P7]).forEach((key) => {
        if (this.answers[questions.CAT_P7][key] == this.answerSeven) {
          question7 = {
            questionCode: questions.CAT_P7,
            answerCode: key,
            value: this.answerSeven,
          };
        }
      });

      Object.keys(this.answers[questions.CAT_P8]).forEach((key) => {
        if (this.answers[questions.CAT_P8][key] == this.answerEight) {
          question8 = {
            questionCode: questions.CAT_P8,
            answerCode: key,
            value: this.answerEight,
          };
        }
      });

      return [
        question1,
        question2,
        question3,
        question4,
        question5,
        question6,
        question7,
        question8,
      ];
    },
    validate() {
      if (!this.isIncompleted) {
        this.save();
      } else {
        this.showToast("Por favor completa la encuesta.", "is-warning", 3000);
      }
    },
    restoreForm() {
      this.answerOne = this.restoreData.answerOne;
      this.answerTwo = this.restoreData.answerTwo;
      this.answerThree = this.restoreData.answerThree;
      this.answerFour = this.restoreData.answerFour;
      this.answerFive = this.restoreData.answerFive;
      this.answerSix = this.restoreData.answerSix;
      this.answerSeven = this.restoreData.answerSeven;
      this.answerEight = this.restoreData.answerEight;

      this.saveTermporalSulrvey();
    },
    saveTermporalSulrvey() {
      this.$session.set("temporalSurvey", {
        module: {
          name: "CAT",
          route: "Cat",
        },
        survey: {
          answerOne: this.answerOne,
          answerTwo: this.answerTwo,
          answerThree: this.answerThree,
          answerFour: this.answerFour,
          answerFive: this.answerFive,
          answerSix: this.answerSix,
          answerSeven: this.answerSeven,
          answerEight: this.answerEight,
        },
      });
    },
    setEvaluationAnswers(data) {
      data.evaluationDetails.forEach((survey) => {
        if (survey.question.code == "CAT_P1") {
          this.answerOne = survey.value;
        }

        if (survey.question.code == "CAT_P2") {
          this.answerTwo = survey.value;
        }

        if (survey.question.code == "CAT_P3") {
          this.answerThree = survey.value;
        }

        if (survey.question.code == "CAT_P4") {
          this.answerFour = survey.value;
        }

        if (survey.question.code == "CAT_P5") {
          this.answerFive = survey.value;
        }

        if (survey.question.code == "CAT_P6") {
          this.answerSix = survey.value;
        }

        if (survey.question.code == "CAT_P7") {
          this.answerSeven = survey.value;
        }

        if (survey.question.code == "CAT_P8") {
          this.answerEight = survey.value;
        }
      });
    },
  },
};
</script>
